import React from "react"
import { Link } from "gatsby"

const AnchorButton = ({
  to,
  children,
  full,
  className,
  styles,
  narrow,
  isAnchor,
}) => {
  const isWhite = className && className.includes("has-text-white")
  const style = {
    width: full ? "100%" : null,
    borderColor: isWhite ? "white" : "black",
    ...styles,
  }

  return isAnchor ? (
    <a href={to} className={`link-btn ${className}`} style={style}>
      <span>{children}</span>
      <span className={`btn-arrow`} style={{ margin: narrow ? 0 : "" }}>
        <i className="icon-arrow" style={{ fontSize: 20 }} />
      </span>
    </a>
  ) : (
    <Link to={to} className={`link-btn ${className}`} style={style}>
      <span>{children}</span>
      <span className={`btn-arrow`} style={{ margin: narrow ? 0 : "" }}>
        <i className="icon-arrow" style={{ fontSize: 20 }} />
      </span>
    </Link>
  )
}

export default AnchorButton

import React from "react"
import AnchorButton from "../elements/anchor-button"
import { useStaticQuery, graphql, Link } from "gatsby"

const Footer = props => {
  const { sanitySiteInfo, allSanitySimplePage } = useStaticQuery(graphql`
    query {
      sanitySiteInfo(site_id: { current: { eq: "veracom" } }) {
        site_meta_data {
          zip
          site_mail
          phone
          cvr
          country
          city
          address
        }
      }
      allSanitySimplePage {
        nodes {
          slug {
            current
          }
          title
        }
      }
    }
  `)

  return (
    <footer className="section has-background-dark has-text-white footer">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="footer__title">Curious?</div>
            <div className="footer__spacer">
              <AnchorButton className="has-text-white" to="/newsletter">
                Get our newsletter
              </AnchorButton>
            </div>
            <div className="footer__spacer">
              <div className="cta-wrapper">
                <AnchorButton
                  isAnchor
                  className="has-text-white"
                  to={`mailto:${sanitySiteInfo.site_meta_data.site_mail}`}
                >
                  Send a message
                </AnchorButton>
              </div>
            </div>
            <div className="footer__title">Follow us</div>
            <a
              className="footer__share"
              href="https://www.facebook.com/vera.mexicana/"
              target="_blank"
            >
              <i className="icon-facebook_round" />
            </a>
            <a
              className="footer__share"
              href="https://www.instagram.com/vera.mexicana/"
              target="_blank"
            >
              <i className="icon-instagram" />
            </a>
            <a
              className="footer__share"
              href="https://www.linkedin.com/company/vera-mexicana/"
              target="_blank"
            >
              <i className="icon-linkedin" />
            </a>
          </div>
          <div className="column">
            <div className="footer__title">Useful Links</div>
            <div className="footer__content">
              <ul>
                <li className="footer__spacer">
                  <a href="https://www.findsmiley.dk/944636" target="_blank">
                    Danish Veterinary and Food Administration
                  </a>
                </li>
                {allSanitySimplePage.nodes.map(page => {
                  return (
                    <li key={page.slug.current} className="footer__spacer">
                      <Link to={`content/${page.slug.current}`}>
                        {page.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="footer__title">Contact Information</div>
            <div className="footer__content">
              <ul>
                <li className="footer__spacer">
                  CVR: {sanitySiteInfo.site_meta_data.cvr}
                </li>
                <li className="footer__spacer">
                  {sanitySiteInfo.site_meta_data.address}
                </li>
                <li className="footer__spacer">
                  {sanitySiteInfo.site_meta_data.zip}{" "}
                  {sanitySiteInfo.site_meta_data.city}
                </li>
                <li className="footer__spacer">
                  <a href={`tel:${sanitySiteInfo.site_meta_data.phone}`}>
                    {sanitySiteInfo.site_meta_data.phone}
                  </a>
                </li>
                <li className="footer__spacer">
                  <a href={`mailto:${sanitySiteInfo.site_meta_data.site_mail}`}>
                    {sanitySiteInfo.site_meta_data.site_mail}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React, { useContext } from "react"
import Img from "gatsby-image"
import { PreviewContext } from "../../context/preview-context"

export const VeraImage = ({ asset, className, contain, imgStyle }) => {
  const { isPreview } = useContext(PreviewContext)
  return isPreview ? (
    <div
      style={{ position: "relative", overflow: "hidden" }}
      className={`${className} gatsby-image-wrapper`}
    >
      <div
        aria-hidden="true"
        style={{ width: "100%", paddingBottom: "75%" }}
      ></div>
      <img
        src={`${asset.url}?fit=crop`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: contain ? "contain" : "cover",
          objectPosition: "center center",
        }}
      />
    </div>
  ) : asset ? (
    <Img
      className={className}
      sizes={{ ...asset.fluid, aspectRatio: 4 / 3 }}
      imgStyle={
        contain ? { ...imgStyle, objectFit: "contain" } : { ...imgStyle }
      }
      style={{ overflow: "initial" }}
    />
  ) : null
}

export const VeraImageSquare = ({ asset, className, contain }) => {
  const { isPreview } = useContext(PreviewContext)
  return isPreview ? (
    <div
      style={{ position: "relative", overflow: "hidden" }}
      className={`${className} gatsby-image-wrapper`}
    >
      <div
        aria-hidden="true"
        style={{ width: "100%", paddingBottom: "100%" }}
      ></div>
      <img
        src={`${asset.url}?fit=crop`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: contain ? "contain" : "cover",
          objectPosition: "center center",
        }}
      />
    </div>
  ) : asset ? (
    <Img
      className={className}
      sizes={{ ...asset.fluid, aspectRatio: 1 / 1 }}
      imgStyle={contain ? { objectFit: "contain" } : {}}
    />
  ) : null
}

export const VeraImageWide = ({ asset, className, contain }) => {
  const { isPreview } = useContext(PreviewContext)
  return isPreview ? (
    <div
      style={{ position: "relative", overflow: "hidden" }}
      className={`${className} gatsby-image-wrapper`}
    >
      <div
        aria-hidden="true"
        style={{ width: "100%", paddingBottom: "56.25%" }}
      ></div>
      <img
        src={`${asset.url}?fit=crop`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: contain ? "contain" : "cover",
          objectPosition: "center center",
        }}
      />
    </div>
  ) : asset ? (
    <Img
      className={className}
      sizes={{ ...asset.fluid, aspectRatio: 16 / 9 }}
      imgStyle={contain ? { objectFit: "contain" } : {}}
    />
  ) : null
}

export const VeraImageHigh = ({ asset, className, contain, imgStyle }) => {
  const { isPreview } = useContext(PreviewContext)
  return isPreview ? (
    <div
      style={{ position: "relative", overflow: "hidden" }}
      className={`${className} gatsby-image-wrapper`}
    >
      <div
        aria-hidden="true"
        style={{ width: "100%", paddingBottom: "125%" }}
      ></div>
      <img
        src={`${asset.url}?fit=crop`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: contain ? "contain" : "cover",
          objectPosition: "center center",
        }}
      />
    </div>
  ) : asset ? (
    <Img
      className={className}
      sizes={{ ...asset.fluid, aspectRatio: 4 / 5 }}
      imgStyle={
        contain ? { ...imgStyle, objectFit: "contain" } : { ...imgStyle }
      }
      style={{ overflow: "initial" }}
    />
  ) : null
}

import SanityBlockContent from "@sanity/block-content-to-react"
import React, { useEffect, useState } from "react"

export default ({ message, color }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const crisisMessageState = window.sessionStorage.getItem(
      "crisisMessageState"
    )
    if (crisisMessageState !== "closed") setIsOpen(true)
  }, [])

  const close = () => {
    window.sessionStorage.setItem("crisisMessageState", "closed")
    setIsOpen(false)
  }

  return (
    <>
      {message && color && (
        <div
          className={`crisis-message ${color} ${
            isOpen ? "is-open" : "is-closed"
          }`}
        >
          <div className={`crisis-message__content`}>
            <SanityBlockContent blocks={message} />
          </div>
          <div onClick={close} className={`crisis-message__close-icon`}>
            <i className="fas fa-times"></i>
          </div>
        </div>
      )}
    </>
  )
}

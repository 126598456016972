import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { bgColorResolver, colorResolver } from "../../util"
import { VeraImage } from "../elements/vera-image"
import AnchorButton from "../elements/anchor-button"

const ShopSubmenuDesktop = ({ shopSubmenuItems }) => {
  const collectionData = useStaticQuery(graphql`
    query {
      allSanityCollection {
        nodes {
          _id
          featured_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          color {
            section_background
          }
          collection_title
          pitch
          slug {
            current
          }
        }
      }
    }
  `)

  const collectionTeasers = []

  while (collectionTeasers.length < 1) {
    const index = Math.floor(
      Math.random() * collectionData.allSanityCollection.nodes.length
    )
    if (
      !collectionTeasers.includes(
        collectionData.allSanityCollection.nodes[index]
      )
    ) {
      collectionTeasers.push(collectionData.allSanityCollection.nodes[index])
    }
  }

  const subMenuDesktop = []
  let aux = []
  shopSubmenuItems.forEach((item, i) => {
    if (aux.length >= 2) {
      subMenuDesktop.push(aux)
      aux = []
    }
    aux.push(item)
    if (shopSubmenuItems.length - 1 === i) {
      subMenuDesktop.push(aux)
    }
  })

  return (
    <div className="columns">
      {subMenuDesktop.map((submenuItem, i) => {
        return (
          <div key={`shopmenu__${i}`} className="column">
            {submenuItem.map((menuItem, j) => {
              const list = (
                <React.Fragment key={menuItem.field_title}>
                  <p className="navbar__submenu-title">
                    <Link to={`/shop/${menuItem.slug.current}`}>
                      {menuItem.field_title}
                    </Link>
                  </p>
                  <ul className="navbar__submenu-list">
                    {menuItem.field_menu_items.map((shopItem, k) => {
                      return (
                        <li
                          style={
                            j === submenuItem.length - 1 &&
                            k === menuItem.field_menu_items.length - 1
                              ? { marginBottom: 0 }
                              : {}
                          }
                          className="navbar__submenu-link"
                          key={shopItem.item_collection_title}
                        >
                          <Link
                            to={`/shop/${shopItem.item_collection_reference.slug.current}`}
                          >
                            {shopItem.item_collection_title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </React.Fragment>
              )
              return list
            })}
          </div>
        )
      })}
      {collectionTeasers.map(teaser => {
        const bgColorClass = bgColorResolver(teaser.color.section_background)
        const colorClass = colorResolver(teaser.color.section_background)
        return (
          <div className="column" key={teaser._id}>
            <div
              className={`navbar__submenu-teaser ${bgColorClass} ${colorClass}`}
            >
              <p style={{ marginBottom: "1rem" }}>{teaser.pitch}</p>
              <VeraImage asset={teaser.featured_image.asset} contain />
              <AnchorButton
                to={`/shop/${teaser.slug.current}/`}
                full
                styles={{ marginTop: "1rem" }}
                className={colorClass}
              >
                {teaser.collection_title}
              </AnchorButton>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ShopSubmenuDesktop

import React, { useState } from "react"
import { Link } from "gatsby"
import Drawer from "../elements/drawer"
import { CgClose, CgArrowLeft } from "react-icons/cg"

const Navbar = ({ menuOpen, setMenuOpen, menuData }) => {
  const [activeItems, setActiveItems] = useState([
    { menuData, menuTitle: null },
  ])

  const renderMenu = items => {
    const currentItem = items[items.length - 1]
    const menuItems = currentItem.menuData.map((item, i) =>
      !item.children ? (
        <li key={i} className="navbar__item">
          <Link to={item.href}>{item.title}</Link>
        </li>
      ) : (
        <li
          key={i}
          onClick={() =>
            setActiveItems(prevstate => [
              ...prevstate,
              {
                menuData: item.children,
                menuTitle: item.title,
                menuHref: item.href,
              },
            ])
          }
          className="navbar__item"
        >
          {item.title}
        </li>
      )
    )
    currentItem.menuTitle &&
      menuItems.unshift(
        <li key="-1" className="navbar__item navbar__item--title">
          <Link
            to={
              currentItem.menuHref === "/shop/"
                ? "/shop"
                : `/shop/${currentItem.menuHref}`
            }
          >
            {currentItem.menuTitle}
          </Link>
        </li>
      )
    return menuItems
  }
  return (
    <Drawer visible={menuOpen}>
      <nav className="navbar is-hidden-tablet">
        <div className="navbar__close-icon-wrapper">
          {activeItems.length > 1 ? (
            <div
              onClick={() =>
                setActiveItems(prevState => {
                  const current = prevState
                  current.pop()
                  return [...current]
                })
              }
            >
              {
                <div style={{ margin: "10px 10px", fontSize: "1.5rem" }}>
                  <CgArrowLeft />
                </div>
              }
            </div>
          ) : (
            <div
              style={{ margin: "10px 10px", fontSize: "1.5rem" }}
              onClick={() => setMenuOpen(false)}
            >
              <CgClose />
            </div>
          )}
        </div>
        <ul className="navbar__list">{renderMenu(activeItems)}</ul>
      </nav>
    </Drawer>
  )
}

export default Navbar

import React from "react"
import { Link } from "gatsby"
import ShopSubmenuDesktop from "./shop-submenu-desktop"

const Navigation = ({ menuData, shopSubMenuItems }) => {
  let isActive = () => {}
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href)
    isActive = item => {
      if (url.pathname !== "/") {
        if (
          item.href.includes("/explorer") &&
          (url.pathname.includes("/article") ||
            url.pathname.includes("/category"))
        ) {
          return true
        }
        if (item.href !== "/") {
          return url.pathname.includes(item.href)
        }
      } else {
        if (item.href === "/") {
          return true
        }
        return false
      }
    }
  }
  return (
    <>
      <nav className="navbar is-hidden-mobile">
        <ul className="navbar__list">
          {menuData.map((item, i) =>
            item.children ? (
              <li
                key={i}
                className={`navbar__item ${isActive(item) ? "active" : ""}`}
                data-has-children
              >
                <Link
                  className={isActive(item) ? "active-link" : ""}
                  to={item.href}
                >
                  {item.title}
                </Link>
                <div className="navbar__submenu is-hidden-mobile has-background-light">
                  <div className="navbar__submenu-hover-helper"></div>
                  <div className="container">
                    <ShopSubmenuDesktop shopSubmenuItems={shopSubMenuItems} />
                  </div>
                </div>
              </li>
            ) : (
              <li
                key={i}
                className={`navbar__item ${isActive(item) ? "active" : ""}`}
              >
                <Link
                  className={isActive(item) ? "active-link" : ""}
                  to={item.href}
                >
                  {item.title}
                </Link>
              </li>
            )
          )}
        </ul>
      </nav>
    </>
  )
}

export default Navigation

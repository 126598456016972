import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./footer/footer"
import CrisisMessage from "./elements/crisis-message"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      sanitySiteInfo {
        site_global_message {
          _rawMessage

          color
        }
      }
    }
  `)

  const bannerData = data.sanitySiteInfo.site_global_message

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="main">
        {bannerData && (
          <CrisisMessage
            message={bannerData._rawMessage}
            color={bannerData.color}
          />
        )}
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout

import React from "react"
import { motion, AnimatePresence } from "framer-motion"

const Drawer = ({ children, visible }) => {
  const animation = {
    x: "0",
    transition: { ease: "easeOut" },
  }

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="drawer is-hidden-tablet"
          initial={{ x: "-100%" }}
          animate={animation}
          exit={{ ...animation, x: "-100%" }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Drawer
